import { AdmissionFormState } from '../../dashboard/store/admission-form/admission-form.state';
import { DocumentsListAdmission, SetAction } from '../../dashboard/store/admission-form/admission-form.actions';
import { ChangeDetectorRef, Component, EventEmitter, Input, NgModule, OnDestroy, OnInit, Output } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { Select, Store } from '@ngxs/store';
import { take } from 'rxjs/operators';
import { AdmissionDocumentsEnums, RoleEnum } from '../../shared/models';
import dayJs from 'dayjs';
import { ReportStatusEnum } from '../../shared/enums/other.enums';
import { MaskDirective } from '../../shared/directives/mask.directive';
import { AdmissionTypeEnum } from '../../shared/enums/admission.enum';
import { PermissionAccessService } from '../../shared/services/permission-access.service';
import { PermissionAdmissionEnum } from '../../shared/enums/permission.enum';
import { RoleOnlyDirective } from '../../shared/directives/role-only.directive';
import { AdmissionDocumentsModel } from '../../shared/models/admission-documents.model';
import { getParsePhone, rmDuplicatesElementsByKey } from '../../shared/helpers/other';
import { FormControlPipe } from '../../dashboard/form-control.pipe';
import { PHONE_MASK } from '../../shared/helpers/phone-mask';

enum AllDocumentsValueEnum {
  NotAllSelected = 0,
  AllAdmission = 1,
  AllSR = 2,
  AllAdmissionsAndSR = 3,
}

@Component({
  selector: 'app-choosed-documents-admission',
  templateUrl: './choose-documents-admission.component.html',
  styleUrls: ['./choose-documents-admission.component.scss'],
})
export class ChooseDocumentsAdmissionComponent implements OnInit, OnDestroy {
  @Input() isReport = false;
  @Input() isDisableSendAction = false;
  @Input() disableAllButtons = false;
  @Input() id = '';
  @Input() typeOfApplication: AdmissionTypeEnum;
  @Input() withAdmission = false;
  @Input() checkedDocs = null;
  @Input() status: number;
  @Input() isPOC = false;
  @Input() hasSignedAdmission = false;
  @Output() closed: EventEmitter<any> = new EventEmitter();
  @Output() nextStep: any = new EventEmitter();
  @Output() actionSign: any = new EventEmitter();
  @Select(AdmissionFormState.listAdmissionDocuments) listAdmissionDocuments$: Observable<any[]>;
  @Select(AdmissionFormState.listSentDocument) listSentDocument$: Observable<any[]>;
  @Select(AdmissionFormState.formValue) formValue$: Observable<any>;
  @Select(AdmissionFormState.dischargeSummary) dischargeSummary$: Observable<any>;
  @Select(AdmissionFormState.currentCaregiver) currentCaregiver$: Observable<any>;
  next = false;
  documents: AdmissionDocumentsModel[] = null;
  allSelectedAdmission = false;
  allSelectedSR = false;
  activePhoneField = false;
  phoneSend = '';
  phoneSendRep = '';
  emailSend = '';
  emailSendRep = '';
  dataSend = {};
  filter = '';
  phoneMask = PHONE_MASK;
  errors: any[] = [];
  activeEmailField = false;
  phoneFormGroup: FormGroup = this.fb.group({
    hhaPhones: this.fb.array([new FormControl('')]),
  });
  emailFormGroup: FormGroup = this.fb.group({
    hhaEmails: this.fb.array([new FormControl('')]),
  });
  public sentDocks: AdmissionDocumentsModel[] = [];
  public hideSelectAll = false;
  public AdmissionTypeEnum = AdmissionTypeEnum;
  RoleEnum = RoleEnum;
  created = null;
  showCalendars: any = {
    created: false,
  };
  role = this.store.selectSnapshot(({ app }) => app.currentUser?.roles[0]?.title);
  uncheckAdmissionPackage = false;
  uncheckSRPackage = false;
  doubleFields = true;
  protected readonly PermissionAdmissionEnum = PermissionAdmissionEnum;
  private canBeSupervisor;

  constructor(
    private cd: ChangeDetectorRef,
    private store: Store,
    private permissionService: PermissionAccessService,
    private fb: FormBuilder,
  ) {}

  _errorSend = {};

  get errorSend(): { [key: string]: string | [string] } {
    return this._errorSend;
  }

  @Input() set errorSend(value) {
    if (value) {
      this._errorSend = value;
      this.checkError(value);
    }
  }

  get getPhoneSendLength(): number {
    return getParsePhone(this.phoneSend)?.length;
  }

  get getPhoneSendRepLength(): number {
    return getParsePhone(this.phoneSendRep)?.length;
  }

  get selectedDocs(): any[] {
    return rmDuplicatesElementsByKey(
      this.documents.filter((doc: AdmissionDocumentsModel) => doc.checked),
      'key',
    );
  }

  ngOnInit(): void {
    this.patchCreatedField();
    this.permissionService
      .hasPermission(PermissionAdmissionEnum.CanBeSupervisor)
      .pipe(take(1))
      .subscribe((value: boolean) => {
        this.canBeSupervisor = value;
      });
    this.doubleFields = !(this.role === RoleEnum.Employee || this.typeOfApplication === AdmissionTypeEnum.POC);
    combineLatest([this.store.dispatch(new DocumentsListAdmission(this.isPOC ? 'poc' : this.id)), this.listSentDocument$])
      .pipe(take(1))
      .subscribe({
        next: ([documents, sentDocuments]) => {
          const data = documents.admissionForm.listAdmissionDocuments;
          this.documents = data.map((item: AdmissionDocumentsModel) => {
            if (item.key === AdmissionDocumentsEnums.HomemakerSupervisoryReport) {
              return { ...item, checked: true };
            }
            if (item.key === AdmissionDocumentsEnums.DischargeTransferSummaryForm) {
              return { ...item, checked: true };
            }
            if (sentDocuments?.find((doc): boolean => doc === item.key)) {
              return { ...item, checked: true };
            } else {
              return { ...item, checked: false };
            }
          });

          this.sentDocks = JSON.parse(JSON.stringify(this.documents));
          if (this.sentDocks.length) {
            this.hideSelectAll = this.sentDocks.reduce((acc: boolean, item: AdmissionDocumentsModel): boolean => {
              return !item.checked ? item.checked : acc;
            }, true);
          }
          if (this.documents.length) {
            this.allSelectedAdmission = this.uncheckAdmissionPackage = this.documents.filter(
              (doc: AdmissionDocumentsModel): boolean => doc.type === 'admission',
            )?.length
              ? this.documents
                  .filter((doc: AdmissionDocumentsModel): boolean => doc.type === 'admission')
                  .every((doc: AdmissionDocumentsModel) => doc.checked)
              : false;
            this.allSelectedSR = this.uncheckSRPackage = this.documents.filter(
              (doc: AdmissionDocumentsModel): boolean => doc.type === 'supervisory_report',
            )?.length
              ? this.documents
                  .filter((doc: AdmissionDocumentsModel): boolean => doc.type === 'supervisory_report')
                  .every((doc: AdmissionDocumentsModel) => doc.checked)
              : false;
          }
          if (this.documents.length === 1) {
            this.toggle(this.documents[0], true);
            this.toggleStep(true);
          }
          this.cd.detectChanges();
        },
        error: () => {
          this.documents = [];
        },
      });
    this.initForm();
  }

  initForm() {
    if (!this.hasSignedAdmission) {
      this.emailFormGroup.addControl('patientEmail', new FormControl(null, [Validators.required, Validators.email]));
      this.phoneFormGroup.addControl('patientPhone', new FormControl(null, Validators.required));
      this.emailFormGroup.addControl('supervisorEmail', new FormControl(null, [Validators.required, Validators.email]));
      this.phoneFormGroup.addControl('supervisorPhone', new FormControl(null, Validators.required));

      (<FormArray>this.emailFormGroup.controls['hhaEmails']).controls[0].addValidators([Validators.required, Validators.email]);
      (<FormArray>this.phoneFormGroup.controls['hhaPhones']).controls[0].addValidators(Validators.required);

      this.emailFormGroup.updateValueAndValidity();
      this.phoneFormGroup.updateValueAndValidity();
    } else {
      this.currentCaregiver$.subscribe(car => {
        if (car?.isSupervisor) {
          this.emailFormGroup.addControl('supervisorEmail', new FormControl(null, [Validators.required, Validators.email]));
          this.phoneFormGroup.addControl('supervisorPhone', new FormControl(null, Validators.required));
        } else {
          (<FormArray>this.emailFormGroup.controls['hhaEmails']).controls[0].addValidators([Validators.required, Validators.email]);
          (<FormArray>this.phoneFormGroup.controls['hhaPhones']).controls[0].addValidators(Validators.required);
        }
      });
    }
  }

  checkError(error) {
    Object.entries(error).forEach(([key, value]) => {
      if (this.emailFormGroup.controls[key]) {
        this.emailFormGroup.controls[key].setErrors({ message: value[0] });
      }
      if (this.phoneFormGroup.controls[key]) {
        this.phoneFormGroup.controls[key].setErrors({ message: value[0] });
      }
      if (/^hhaEmails.[0-9]*$/.test(key)) {
        const count = key.slice(10, key.length);
        (<FormArray>this.emailFormGroup.controls['hhaEmails']).controls[count]?.setErrors({ message: value[0] });
      }
      if (/^hhaPhones.[0-9]*$/.test(key)) {
        const count = key.slice(10, key.length);
        (<FormArray>this.phoneFormGroup.controls['hhaPhones']).controls[count]?.setErrors({ message: value[0] });
      }
    });
  }

  ngOnDestroy(): void {
    this.created = null;
    this.isDisableSendAction = false;
    this.disableAllButtons = false;
  }

  closeCalendar(formControlName: string): void {
    this.showCalendars[formControlName] = false;
  }

  isDisabledChange(item): boolean {
    if (item.key === AdmissionDocumentsEnums.HomemakerSupervisoryReport) {
      return true;
    }
    if (this.canBeSupervisor && this.status === ReportStatusEnum.ToBeCorrected) {
      return !!this.sentDocks.find((doc: AdmissionDocumentsModel): boolean => doc.key === item.key).checked;
    } else {
      if (this.status !== ReportStatusEnum.Signed && this.status !== ReportStatusEnum.SentForSignatures) {
        return false;
      } else {
        return !!this.sentDocks.find((doc: AdmissionDocumentsModel): boolean => doc.key === item.key).checked;
      }
    }
  }

  selectAll(typeOfApplication): void {
    if (typeOfApplication === AdmissionTypeEnum.Admission) {
      this.allSelectedAdmission = !this.allSelectedAdmission;
      this.documents = this.documents.map((doc: AdmissionDocumentsModel) => {
        if (this.isDisabledChange(doc) || doc.type === 'supervisory_report') {
          return doc;
        } else {
          return { ...doc, checked: this.allSelectedAdmission };
        }
      });
    } else {
      this.allSelectedSR = !this.allSelectedSR;
      this.documents = this.documents.map((doc: AdmissionDocumentsModel) => {
        if (this.isDisabledChange(doc) || doc.type === 'admission') {
          return doc;
        } else {
          return { ...doc, checked: this.allSelectedSR };
        }
      });
    }
  }

  toggle(document: AdmissionDocumentsModel, value: boolean = false): void {
    const currentDoc: AdmissionDocumentsModel = this.documents.find(
      (doc: AdmissionDocumentsModel) => doc.type === document.type && doc.key === document.key,
    );
    currentDoc.checked = value ? value : !currentDoc.checked;
    this.allSelectedAdmission = this.documents.filter((doc: AdmissionDocumentsModel) => doc.type === 'admission' && doc.checked).length
      ? this.documents.filter((doc: AdmissionDocumentsModel) => doc.type === 'admission' && doc.checked)?.length ===
        this.documents.filter((doc: AdmissionDocumentsModel): boolean => doc.type === 'admission')?.length
      : false;
    this.allSelectedSR = this.documents.filter((doc: AdmissionDocumentsModel) => doc.type === 'supervisory_report' && doc.checked)?.length
      ? this.documents.filter((doc: AdmissionDocumentsModel) => doc.type === 'supervisory_report' && doc.checked)?.length ===
        this.documents.filter((doc: AdmissionDocumentsModel): boolean => doc.type === 'supervisory_report')?.length
      : false;
  }

  chooseAction(action): void {
    if (action === 'sign') {
      this.disableAllButtons = true;
      this.dataSend = {
        isFromAction: true,
        keys: this.selectedDocs.map((item: AdmissionDocumentsModel) => item.key),
        isAllDocuments: this.getIsAllDocumentsValue(),
        createdAt: dayJs(this.created).isValid() ? dayJs(this.created).format('YYYY-MM-DD') : null,
      };
      this.store.dispatch(new SetAction({ name: 'actionSetStarted', data: this.dataSend }));
      this.activeEmailField = false;
      this.activePhoneField = false;
    } else if (action === 'email') {
      this.isDisableSendAction = true;
      this.activePhoneField = false;
      this.activeEmailField = true;
      this.phoneFormGroup.reset();
      this.phoneSend = '+1';
      this.phoneSendRep = '+1';
      this.errorSend = {};
    } else if (action === 'phone') {
      this.isDisableSendAction = true;
      this.emailSend = '';
      this.emailSendRep = '';
      this.emailFormGroup.reset();
      this.errorSend = {};
      this.activePhoneField = true;
      this.activeEmailField = false;
    }
  }

  parsePhone(phone): string {
    let newPhone: string;
    if (phone) {
      if (phone.length >= 16) {
        newPhone = phone.replace(/-*_*\(*\)* */g, '').substr(0, 12);
      } else {
        newPhone = phone.replace(/-*_*\(*\)* */g, '').substr(0, 12);
      }
    } else {
      newPhone = '';
    }
    return newPhone === '+1' ? '' : newPhone;
  }

  sendDocs(action, poc = false): void {
    this.isDisableSendAction = true;
    let placeForSend: { [key: string]: string | string[] };
    if (action === 'email' && !poc) {
      placeForSend = { email: this.emailSend, secondEmail: this.emailSendRep };
    } else if (action === 'phone' && !poc) {
      placeForSend = { phone: this.parsePhone(this.phoneSend), secondPhone: this.parsePhone(this.phoneSendRep) };
    } else if (action === 'email' && poc) {
      placeForSend = this.emailFormGroup.value;
    } else {
      const phones = this.phoneFormGroup.value;
      Object.keys(this.phoneFormGroup.value).forEach(k => {
        if (phones[k] instanceof Array) {
          phones[k] = phones[k].map(v => this.parsePhone(v));
        } else {
          phones[k] = this.parsePhone(phones[k]);
        }
      });
      placeForSend = phones;
    }

    this.dataSend = {
      action,
      placeForSend,
      keys: this.selectedDocs.map((item: AdmissionDocumentsModel) => item.key),
      isAllDocuments: this.getIsAllDocumentsValue(),
      createdAt: dayJs(this.created).isValid() ? dayJs(this.created).format('YYYY-MM-DD') : null,
    };
    this.nextStep.emit(this.dataSend);
    this.store.dispatch(new SetAction({ name: 'sentDocs', data: this.dataSend }));
  }

  toggleStep(value: boolean = false): void {
    this.filter = '';
    if (this.documents.filter((doc: AdmissionDocumentsModel) => doc.checked).length) {
      this.next = value ? value : !this.next;
    }
  }

  showDoc(docName): boolean {
    return this.filter === '' || docName.toLowerCase().includes(this.filter.toLowerCase());
  }

  addHHA(type: 'Email' | 'Phone') {
    if (type === 'Phone') {
      (<FormArray>this.phoneFormGroup.controls['hhaPhones']).push(new FormControl(''));
    }
    if (type === 'Email') {
      (<FormArray>this.emailFormGroup.controls['hhaEmails']).push(new FormControl(''));
    }
  }

  removeHHA(type: 'Email' | 'Phone', i: number) {
    if (type === 'Phone') {
      (<FormArray>this.phoneFormGroup.controls['hhaPhones']).removeAt(i);
    }
    if (type === 'Email') {
      (<FormArray>this.emailFormGroup.controls['hhaEmails']).removeAt(i);
    }
  }

  public removeError(name): void {
    delete this.errorSend[name];
    if (!this.errorSend || (this.errorSend && !Object.keys(this.errorSend || {})?.length)) {
      this.isDisableSendAction = false;
    }
  }

  disabledActions(flag) {
    this.isDisableSendAction = flag;
    this.cd.detectChanges();
  }

  private patchCreatedField(): void {
    if (this.typeOfApplication === AdmissionTypeEnum.DischargeSummary) {
      this.dischargeSummary$.pipe(take(1)).subscribe(form => {
        this.created = dayJs(form.createdAt).isValid() ? dayJs(form.createdAt).format('MM/DD/YYYY') : '';
      });
    } else {
      this.formValue$.pipe(take(1)).subscribe((formValue: any) => {
        this.created = dayJs(formValue.createdAt).isValid() ? dayJs(formValue.createdAt).format('MM/DD/YYYY') : '';
      });
    }
  }

  private getIsAllDocumentsValue(): AllDocumentsValueEnum {
    if (this.allSelectedAdmission && this.allSelectedSR) {
      return AllDocumentsValueEnum.AllAdmissionsAndSR;
    }
    if (this.allSelectedAdmission && !this.allSelectedSR) {
      return AllDocumentsValueEnum.AllAdmission;
    }
    if (!this.allSelectedAdmission && this.allSelectedSR) {
      return AllDocumentsValueEnum.AllSR;
    }
    if (!this.allSelectedAdmission && !this.allSelectedSR) {
      return AllDocumentsValueEnum.NotAllSelected;
    }
  }
}

@NgModule({
  declarations: [ChooseDocumentsAdmissionComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaskDirective, SharedModule, RoleOnlyDirective, FormControlPipe],
  exports: [ChooseDocumentsAdmissionComponent],
  providers: [],
})
export class ChooseDocumentsAdmissionModule {}
